@import './common.less';

.parts-summary {
  .page-notice__main p > button {
    margin-top: 20px;
  }
  &__image {
    height: 165px;
  }

  &__left {
    .card-tile-vertical__card {
      padding: 8px 0;
      &-details > ul {
        margin: 0;
        li {
          color: var(--color-stroke-default);
        }
      }
    }
  }

  &__content {
    padding: 24px 16px 0;
    height: ~"calc(100vh - 445px)";
    height: calc(100 * var(--pivh) - 445px);
    overflow-y: scroll;
  }

  &__footer {
    padding: 0 16px;
  }
  .sticky_footer {
    height: 180px;
    .parts-summary__footer-text {
      .typography-small();
      p {
        padding-right: 24px;
      }
      span.ux-textspans--PSEUDOLINK {
        color: var(--color-foreground-primary);
      }
    }
  }
  &__right {
    padding-left: 8px;
    .parts-summary__feild-input {
      margin-bottom: 16px;
      input {
        text-indent: 14px;
      }
    }
  }
  &-loading {
    .screen-loading {
      padding: 0 16px 16px;
      height: 80%;
      &__content-top {
        .skeleton__textbox {
          height: 165px;
        }
      }
      &__content-main {
        padding-top: 32px;
      }
      &__content-left {
        .skeleton {
          padding-bottom: 32px;
        }
      }
    }
  }
}
.parts-summary.error {
  .parts-summary__content {
    height: ~"calc(100vh - 560px)";
    height: calc(100 * var(--pivh) - 560px);
  }
}
